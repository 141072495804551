<template>
  <div>
    <TheHeader />
    <div class="columns">
      <main class="column">
        <slot />
      </main>
    </div>
  </div>
</template>

<style scoped>
main {
  /* FIXME: はみ出し防止の暫定措置 */
  max-width: 90%;
}
</style>
